import React from "react";
import { Auth, API } from "aws-amplify";
import { Button, TextField } from "@aws-amplify/ui-react";
// Graphql
import { listUsers, getOrganizations } from "../graphql/queries";

export class AppSync extends React.Component {
	constructor(props) {
		super(props);
		this.handleTispChange = this.handleTispChange.bind(this);
		this.getAuthToken = this.getAuthToken.bind(this);
		this.listUsersReq = this.listUsersReq.bind(this);
		this.getOrganizationsReq = this.getOrganizationsReq.bind(this);
		this.state = {
			noteId: "",
			tispToken: "",
		};
	}

	handleTispChange(event) {
		this.setState({ tispToken: event.target.value });
	}

	async getAuthToken() {
		const authToken = this.state.tispToken
			? this.state.tispToken
			: (await Auth.currentSession()).getAccessToken().getJwtToken();
		return authToken;
	}

	async listUsersReq() {
		try {
			const authToken = await this.getAuthToken();
			const users = await API.graphql({ query: listUsers, authToken: authToken });
			console.log(users);
			alert(JSON.stringify(users.data.users));
		} catch (error) {
			alert(error);
		}
	}

	async getOrganizationsReq() {
		try {
			const authToken = await this.getAuthToken();
			const orgs = await API.graphql({ query: getOrganizations, authToken: authToken });
			console.log(orgs);
			alert(JSON.stringify(orgs.data.getOrganizations));
		} catch (error) {
			alert(error);
		}
	}

	render() {
		return (
			<div>
				<h2>Demo for AppSync API call: </h2>
				<TextField
					descriptiveText="Enter your TISP token (If entered, this will be sent instead of the Cognito token)."
					label="TISP token"
					errorMessage="There is an error"
					onChange={this.handleTispChange}
					value={this.state.tispToken}
					id="tispToken"
				/>
				<br />
				<Button onClick={this.listUsersReq}>List Users</Button>
				<Button onClick={this.getOrganizationsReq}>List Orgs</Button>
			</div>
		);
	}
}
