import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { Amplify } from "aws-amplify";
import App from "./App";
import "./index.css";

Amplify.configure({
	Auth: {
		// REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
		identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
		// identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

		// REQUIRED - Amazon Cognito Region
		region: process.env.REACT_APP_REGION,
		// region: 'us-west-1',

		// OPTIONAL - Amazon Cognito Federated Identity Pool Region
		// Required only if it's different from Amazon Cognito Region
		// identityPoolRegion: 'XX-XXXX-X',,

		// OPTIONAL - Amazon Cognito User Pool ID
		userPoolId: process.env.REACT_APP_USER_POOL_ID,
		// userPoolId: 'eu-west-1_XXXX',

		// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
		userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
		// userPoolWebClientId: 'XXXXX',

		// OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
		// mandatorySignIn: false,

		// OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
		// 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
		// signUpVerificationMethod: 'code', // 'code' | 'link'

		// OPTIONAL - Configuration for cookie storage
		// Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
		// cookieStorage: {
		// REQUIRED - Cookie domain (only required if cookieStorage is provided)
		// domain: ".yourdomain.com",
		// OPTIONAL - Cookie path
		// path: "/",
		// OPTIONAL - Cookie expiration in days
		// expires: 365,
		// OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
		// sameSite: "strict" | "lax",
		// OPTIONAL - Cookie secure flag
		// Either true or false, indicating if the cookie transmission requires a secure protocol (https).
		// secure: false,
		// },

		// OPTIONAL - customized storage object
		// storage: MyStorage,

		// OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
		// authenticationFlowType: 'USER_PASSWORD_AUTH',

		// OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
		// clientMetadata: { myCustomKey: 'myCustomValue' },

		// OPTIONAL - Hosted UI configuration
		oauth: {
			domain: process.env.REACT_APP_DOMAIN,
			scope: ["profile", "email", "openid", "aws.cognito.signin.user.admin", "phone"],
			redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN,
			redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT,
			responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
		},
	},
	API: {
		endpoints: [
			{
				name: "auth-api",
				region: process.env.REACT_APP_REGION,
				endpoint: process.env.REACT_APP_API_URL,
			},
		],
	},
	aws_appsync_graphqlEndpoint: process.env.REACT_APP_APPSYNC_URL,
	aws_appsync_authenticationType: process.env.REACT_APP_AUTH_TYPE,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<Router>
			<App />
		</Router>
	</React.StrictMode>
);
