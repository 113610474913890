import React from "react";

export class Greeting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: props.email,
        };
    }
    render() {
        return (
            <div >
                <h1>Hello {this.state.email}</h1>
            </div>
        );
    }
}