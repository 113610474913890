import React from "react";
import { API, Auth } from "aws-amplify";
import { Button, TextField } from "@aws-amplify/ui-react";

export class HttpApi extends React.Component {
	constructor(props) {
		super(props);
		this.handleOrgChange = this.handleOrgChange.bind(this);
		this.handleTispChange = this.handleTispChange.bind(this);
		this.getAuthToken = this.getAuthToken.bind(this);
		this.httpReq = this.httpReq.bind(this);
		this.state = {
			selectedOrg: "",
			tispToken: "",
		};
	}

	handleOrgChange(event) {
		this.setState({ selectedOrg: event.target.value });
	}

	handleTispChange(event) {
		this.setState({ tispToken: event.target.value });
	}

	async getAuthToken() {
		const authToken = this.state.tispToken
			? this.state.tispToken
			: (await Auth.currentSession()).getAccessToken().getJwtToken();
		return authToken;
	}

	async httpReq() {
		try {
			const authToken = await this.getAuthToken();
			const response = await API.get("auth-api", "/orgs/exampleOrg", {
				headers: {
					"Authorization": `Bearer ${authToken}`,
					"Org-Id": this.state.selectedOrg,
				},
			});
			console.log(response);
			alert(JSON.stringify(response));
		} catch (error) {
			alert(error);
		}
	}

	render() {
		return (
			<div className="api-section">
				<h2>Demo for HTTP API call: </h2>
				<TextField
					descriptiveText="Select your organization."
					label="Organization"
					errorMessage="There is an error"
					onChange={this.handleOrgChange}
					value={this.state.selectedOrg}
					id="orgSelector"
				/>
				<br />
				<TextField
					descriptiveText="Enter your TISP token (If entered, this will be sent instead of the Cognito token)."
					label="TISP token"
					errorMessage="There is an error"
					onChange={this.handleTispChange}
					value={this.state.tispToken}
					id="tispToken"
				/>
				<br />
				<Button onClick={this.httpReq}>HTTP Get Request</Button>
			</div>
		);
	}
}
