export const listUsers = `
query listUsers {
  users {
      email
      id
      info
    }
  }
  `;

export const getOrganizations = `
query getOrganizations {
  getOrganizations {
    id
    orgId
    version
    roleKeys
    userId
    }
  }
  `;
