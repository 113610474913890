import React from "react";
import { Auth } from "aws-amplify";
import { Button, TextField, SwitchField } from "@aws-amplify/ui-react";
import QRCode from "qrcode";

export class Mfa extends React.Component {
	constructor(props) {
		super(props);
		this.handleMfaAnswer = this.handleMfaAnswer.bind(this);
		this.setQrCode = this.setQrCode.bind(this);
		this.verifyMfa = this.verifyMfa.bind(this);
		this.associateSoftworeToken = this.associateSoftworeToken.bind(this);
		this.state = {
			mfaAnswer: "",
			qrCode: "",
			isChecked: props.user.preferredMFA !== "NOMFA",
		};
	}

	handleMfaAnswer(event) {
		this.setState({ mfaAnswer: event.target.value });
	}

	setQrCode(qrCodeImageSource) {
		this.setState({ qrCode: qrCodeImageSource });
	}

	setPreferredMFA(status) {
		this.setState({ preferredMFA: status });
	}

	async associateSoftworeToken(event) {
		this.setState({ isChecked: event.target.checked });
		try {
			const user = await Auth.currentAuthenticatedUser();
			if (user.preferredMFA === "NOMFA" && event.target.checked) {
				console.info("run mfa code association for this user");
				const response = await Auth.setupTOTP(user);
				const totpUri = encodeURI(`otpauth://totp/AwsCognito:${user.username}?secret=${response}&issuer=AwsCognito`);
				const qrCodeImageSource = await QRCode.toDataURL(totpUri);
				this.setQrCode(qrCodeImageSource);
			} else if (user.preferredMFA !== "NOMFA" && !event.target.checked) {
				console.info("disable mfa for this user");
				await Auth.setPreferredMFA(user, "NOMFA");
				this.setQrCode("");
			} else if (user.preferredMFA === "NOMFA" && !event.target.checked) {
				this.setQrCode("");
			}
		} catch (error) {
			alert(error);
		}
	}

	async verifyMfa() {
		try {
			if (!this.state.mfaAnswer) return;
			const user = await Auth.currentAuthenticatedUser();
			await Auth.verifyTotpToken(user, this.state.mfaAnswer);
			await Auth.setPreferredMFA(user, "TOTP");
			this.setState({ mfaAnswer: "", qrCode: "" });
		} catch (error) {
			alert(error);
		}
	}

	render() {
		return (
			<div>
				<h2>Demo for MFA setup: </h2>
				<p> Your MFA status: {this.props.user.preferredMFA} </p>
				<div>
					<SwitchField
						label="Get MFA setup"
						labelPosition="start"
						onChange={this.associateSoftworeToken}
						id="MfaEnabled"
						isChecked={this.state.isChecked}
					/>
					<br />
					{this.state.qrCode ? (
						<div>
							<p> Please scan this code: </p>
							<img src={this.state.qrCode} alt="qr code to setup totp" />
						</div>
					) : (
						<div> {this.state.qrCode} </div>
					)}
				</div>
				<br />
				<TextField
					descriptiveText="Enter MFA answer."
					label="MFA code"
					errorMessage="There is an error"
					onChange={this.handleMfaAnswer}
					value={this.state.mfaAnswer}
					id="MfaAnswer"
				/>
				<br />
				<Button onClick={this.verifyMfa}>Submit MFA Code</Button>
			</div>
		);
	}
}
