// React
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
// Amplify
import { Auth } from "aws-amplify";
import { Authenticator, Button, Grid, Card } from "@aws-amplify/ui-react";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import "@aws-amplify/ui-react/styles.css";
// Other files
import { components, formFields, services } from "./customAuthenticator";
import { Greeting } from "./constructs/Greeting";
import { HttpApi } from "./constructs/HttpApi";
import { Mfa } from "./constructs/Mfa";
import { AppSync } from "./constructs/AppSync";

/*
 * This is the Home construct which is rendered in root path (/)
 */
export const Home = () => {
	const [searchParams] = useSearchParams();

	useEffect(() => {
		const currentParams = Object.fromEntries([...searchParams]);
		if (currentParams.retry_auth && currentParams.retry_auth.includes("google")) {
			handleGoogleLogin();
		}
	}, [searchParams]);
	const handleGoogleLogin = async () => {
		try {
			await Auth.federatedSignIn({
				provider: CognitoHostedUIIdentityProvider.Google,
			});
		} catch (e) {
			console.log(`Google login error`, e);
		}
	};

	return (
		<div>
			<Authenticator
				socialProviders={["google"]}
				hideSignUp={true} // hides the sign up tab
				components={components}
				formFields={formFields}
				services={services}
			>
				{({ signOut, user }) => (
					<main>
						<Grid columnGap="0.5rem" rowGap="0.1rem" templateColumns="1fr 1fr 1fr" templateRows="1fr 3fr 1fr">
							<Greeting email={user.attributes.email} />
							<div className="sign-out" align="right">
								<br />
								<Button onClick={signOut}>Sign out</Button>
							</div>
							<Card columnStart="1" columnEnd="2">
								<HttpApi />
							</Card>
							<Card columnStart="2" columnEnd="3">
								<AppSync />
							</Card>
							<Card columnStart="3" columnEnd="4">
								<Mfa user={user} />
							</Card>
						</Grid>
					</main>
				)}
			</Authenticator>
		</div>
	);
};
