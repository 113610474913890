import { Heading, useTheme } from "@aws-amplify/ui-react";
import { translations } from "@aws-amplify/ui-react";
import { I18n, Auth } from "aws-amplify";
I18n.putVocabularies(translations);
I18n.setLanguage("sv");

const GLOBAL_TISP = false;

export const components = {
	SignIn: {
		Header() {
			const { tokens } = useTheme();

			return (
				<Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
					Logga in på VGCS
				</Heading>
			);
		},
	},
	// enter more if needed
};

export const formFields = {};

export const services = {
	async handleSignIn(formData) {
		let { username, password } = formData;

		if (GLOBAL_TISP) {
			alert("Todo: implement tisp login!");
			throw new Error("TISP login not implemented");
		} else {
			return Auth.signIn({
				username,
				password,
			});
		}
	},
};
