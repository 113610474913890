// React
import React from "react";
import { useRoutes } from 'react-router-dom';
// Amplify
import '@aws-amplify/ui-react/styles.css';
// Other files
import { Home } from "./Home";

/*
* This is entry point. It contains a router which sets the routes and components.
*/
export default function App() {
  let router = useRoutes([
    { path: '/', element: <Home /> },
  ]);
  return router;
}
